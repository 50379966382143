import { FavoritesTable } from 'components/Tables/FavoritesTable';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { SingleFlatType } from 'types/CommonTypes';
import { Container, SectionTitle } from './Favorites.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { FavoritesListMobile } from 'sections/FavoritesListMobile/FavoritesListMobile';
export interface FavoritesProps {
    data: SingleFlatType[];
    investmentSlug: string;
}

export const Favorites = ({ data, investmentSlug }: FavoritesProps) => {
    const { liked } = useTheme();
    const filterFavoritesFlats = (data: SingleFlatType[]) => {
        return data.filter((flat) =>
            liked.includes(String(flat.flatNumber + flat.investmentBuilding)),
        );
    };

    const isMobile = useBreakpoint('md');

    return (
        <Container>
            <SectionTitle>Ulubione mieszkania</SectionTitle>
            {!isMobile && (
                <FavoritesTable
                    data={filterFavoritesFlats(data)}
                    investmentSlug={investmentSlug}
                />
            )}
            {isMobile && (
                <FavoritesListMobile
                    flatsData={filterFavoritesFlats(data)}
                    investmentSlug={investmentSlug}
                />
            )}
        </Container>
    );
};
