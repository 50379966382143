import styled, { css } from 'styled-components';
import { LoadMoreButton } from 'styles/Table.styled';

export const FlatsListMobileWrapper = styled.div(
    ({ theme: { colors } }) => css`
        width: 100%;
        align-items: center;
        background-color: ${colors.primaryBlue60};
    `,
);

export const FlatCardWrapper = styled.div`
    margin-bottom: 32px;
`;

export const LoadMoreStyled = styled(LoadMoreButton)`
    margin: 0 36px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
`;
