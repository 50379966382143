import { FlatCard } from 'components/FlatCard/FlatCard';
import React, { useState } from 'react';
import { SingleFlatType } from 'types/CommonTypes';
import {
    FlatCardWrapper,
    FlatsListMobileWrapper,
    LoadMoreStyled,
} from './FavoritesListMobile.styled';

export interface FlatsHeroMobileProps {
    flatsData: SingleFlatType[];
    investmentSlug: string;
}

export const FavoritesListMobile = ({
    flatsData,
    investmentSlug,
}: FlatsHeroMobileProps) => {
    const [numberOfRows, setNumberOfRows] = useState(5);

    const loadMore = () => {
        setNumberOfRows((prevState) => prevState + 5);
    };

    const cutFlats = flatsData.slice(0, numberOfRows);

    return (
        <FlatsListMobileWrapper>
            {cutFlats?.map((flat: SingleFlatType, index: number) => {
                return (
                    <FlatCardWrapper>
                        <FlatCard
                            flatInfo={flat}
                            key={index}
                            investmentSlug={investmentSlug}
                        />
                    </FlatCardWrapper>
                );
            })}
            {flatsData.length > numberOfRows && (
                <LoadMoreStyled onClick={loadMore}>
                    Załaduj więcej
                </LoadMoreStyled>
            )}
        </FlatsListMobileWrapper>
    );
};
